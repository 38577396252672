<script setup lang="ts">
import ui from '/~/core/ui'
import OoCatalog from '/~/extensions/online-offers/components/oo-catalog.vue'
import OoFilter from '/~/extensions/online-offers/components/oo-filter.vue'
import { useEditablePage } from '/~/composables/cms/use-editable-page'

const { page } = useEditablePage()
</script>

<template>
  <div class="mx-auto w-full max-w-7xl">
    <oo-filter v-if="ui.mobile" />
    <div v-else class="px-5 pb-3 pt-2">
      <oo-filter />
    </div>
    <widgets-builder v-if="page && page.widgets" :widgets="page.widgets" />

    <div class="p-2.5 sm:p-5">
      <oo-catalog />
    </div>
  </div>
</template>
